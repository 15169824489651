import { useContext } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import LocaleContext from '../contexts/locale';
import { useHighlightByUuid } from './use-highlights';

const all = [];
const uuidMap = {};
const localeMap = {};
const featuredMap = {};

export const useOfferByUuid = uuid => {
	invokeQueryAndMapData();

	if (uuid in uuidMap) {
		return uuidMap[uuid];
	}

	return null;
};

export const useOfferFeaturedByUuid = uuid => {
	invokeQueryAndMapData();

	if (uuid in featuredMap) {
		return featuredMap[uuid];
	}

	return [];
};

const invokeQueryAndMapData = () => {
	const {
		allMarkdownRemark: { edges: locations }
	} = useStaticQuery(query);

	const { currentLocale } = useContext(LocaleContext);

	const mapItem = ({ node: { frontmatter: data, fields } }) => ({
		...data,
		...fields
	});

	if (all.length === 0) {
		for (let location of locations) {
			const item = mapItem(location);
			const { uuid, locale, highlights } = item;

			// map the first image of a slider's `items` property
			// and save them in hash map based on slider's index:
			let featuredImagesItem = {};
			if (Array.isArray(highlights)) {
				highlights.forEach(({ items }, index) => {
					if (!Array.isArray(items)) {
						return;
					}

					featuredImagesItem[index] = items.map(uuid => {
						const {
							slug,
							name,
							images: [image]
						} = useHighlightByUuid(uuid);

						return { uuid, slug, name, image };
					});
				});
			}

			featuredMap[uuid] = featuredImagesItem;
			localeMap[locale] = localeMap[locale] || [];
			localeMap[locale].push(item);
			uuidMap[uuid] = item;
			all.push(item);
		}
	}

	return localeMap[currentLocale] || [];
};

export default invokeQueryAndMapData;

const query = graphql`
	query useOffers {
		allMarkdownRemark(
			filter: { frontmatter: { type: { eq: "offer" } } }
			sort: { fields: frontmatter___navigation___order }
		) {
			edges {
				node {
					fields {
						slug
						parent
					}
					frontmatter {
						locale
						uuid
						name
						navigation {
							icon {
								publicURL
							}
						}
						highlights {
							title
							items
							text
							image {
								childImageSharp {
									fluid(maxWidth: 1650, cropFocus: CENTER) {
										...GatsbyImageSharpFluid
									}
								}
							}

						}
						galleries
						videos
					}
				}
			}
		}
	}
`;
