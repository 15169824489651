import React, { useState, useEffect, useContext } from 'react';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';

import Modal from '../../components/modal';
import Slider from '../../components/slider';
import { HTML } from '../../components/utils';
import { SM } from '../../config/breakpoints';
import MetaContext from '../../contexts/meta';
import { useHighlightByUuid } from '../../hooks/use-highlights';

import Icons from './icons';

import styles from './highlight-modal.module.scss';

const HighlightModal = ({ uuid, onClose }) => {
  const { title, description, name, images, icons, html } =
    useHighlightByUuid(uuid) || {};
  const {
    setTitles,
    setDescription,
    titles: currentTitles,
    description: currentDescription
  } = useContext(MetaContext);
  const [isClient, setIsClient] = useState(false);
  const isMinSM = useMediaQuery({ minWidth: SM });

  useEffect(() => {
    setDescription(description);
    setTitles([...[title], ...currentTitles]);

    return () => {
      setTitles(currentTitles);
      setDescription(currentDescription);
    };
  }, []);

  useEffect(() => {
    setIsClient(true);
  }, []);

  const slidesToShow = (isClient && isMinSM && 3) || 2;

  return (
    <Modal title={name} onClose={onClose}>
      {Array.isArray(images) && (
        <Slider inlineArrows={true} className={styles.slider} slidesToShow={slidesToShow}>
          {images.map(({ childImageSharp: { fluid } }, index) => (
            <Img key={index} fluid={fluid} className={styles.image} alt={title} />
          ))}
        </Slider>
      )}

      <div className={styles.icons}>
        <Icons icons={icons} />
      </div>

      <div className={styles.content}>
        <HTML content={html} />
      </div>
    </Modal>
  );
};

HighlightModal.defaultProps = {
  uuid: null,
  onClose: () => {}
};

HighlightModal.propTypes = {
  uuid: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired
};

export default HighlightModal;
